<template>
  <div>
    <section class="speciality-area">
      <div class="container">
        <div v-if="viewPaper" style="max-width: 1000px; margin: auto;">
          <div v-for="mainPageObj in mainPageObjList" :key="mainPageObj.id"
            class="main-paper-header p-2 border shadow ">
            <div class="article-header justify-content-between row">
              <h4 class="col-8 col-sm-9 col-md-10">{{ mainPageObj.title }}</h4>
              <div class="col-4 col-sm-3 col-md-2">
                <button type="button" @click="openPaperDetails(mainPageObj.url_title)" class="viewArticlebtn p-2 float-right">
                  View Article
                </button>
              </div>
            </div>
            <div class="col">
              <h6>{{ mainPageObj.title }}</h6>
              <p><b>Category By: </b> {{ mainPageObj.category }}</p>
              <p><b>Date: </b> {{ formatDate(mainPageObj.created_at) }}</p>
            </div>
          </div>
        </div>

        <div v-if="!viewPaper && paperpage" style="max-width: 800px; margin: auto;" class="container">
          <div v-if="!paperDetails">
            Loading...
          </div>
          <div v-else>
            <h1 class="m-0">{{ paperDetails.title }}</h1>
            <h6 class="form-control-sm text-gray px-0 mt-2"><strong>Category: </strong> <span>{{ paperDetails.category
            }}</span>
            </h6>
            <h6 class="form-control-sm text-gray p-0 mt-0 mb-0"><strong>Date: </strong> <span>{{
              formatDate(paperDetails.created_at)
            }}</span></h6>
            
            <div v-if="this.paperDetails.image!=='https://ijsreat.com/blogImage/' && this.paperDetails.image!==undefined|null"
              class="d-flex align-content-center justify-content-center pb-5">
              <img class="mx-auto block" style="max-width: 25rem;" :src="paperDetails.image" alt="Image">
            </div>

            <article class="article " v-html="artical()"></article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      viewPaper: true,
      paperpage: false,
      paperDetails: null,
      mainPageObjList: [],
    };
  },

  metaInfo() {
    return {
      title: this.paperDetails?.meta_title || 'Blogs | International Journal | IJSREAT',
      meta: [
        {
          name: 'description',
          content: this.paperDetails && this.paperDetails.meta_description
            ? `${this.paperDetails.meta_description.split('.')[0]}.`
            : 'Access IJSREAT archives to explore past issues. Browse research articles in Engineering, Technology, and Applied Sciences from previous editions.'
        },
        {
          name: 'keywords',
          content: this.paperDetails?.tags || 'International Journal, IJSREAT, Engineering, Technology, Applied Sciences, Research Articles, Archives'
        },
        {
          property: 'og:title',
          content: this.paperDetails?.meta_title || document.title
        },
        {
          property: 'og:url',
          content: window.location.href
        }
      ],
      link: [{ rel: 'canonical', href: window.location.href }]
    };
  },
  methods: {
    formatDate(dateString) {
      return dateString ? dateString.split('T')[0] : 'N/A';
    },

    openPaperDetails(id) {
      this.$router.push({ path: `blogs/${id}`});
      this.viewPaper = false;
      this.paperpage = true;
    },

    artical() {
      if (!this.paperDetails || !this.paperDetails.description) return '';
      return this.paperDetails.description.replace("<h1>",`<h1 style="display:none">`,1)
    },

    async getBlogData() {
      try {
        const res = await this.api.postData("blogFetch");
        this.mainPageObjList = res.data.blogList || [];
      } catch (err) {
        console.error("Error fetching blog data:", err);
      }
    },

    async getBlogDetails(paperid) {
   try {
     const res = await this.api.postDataModulePagination("blogDetails", { url_title: paperid });
     this.paperDetails = res.data?.blogDetails || {};
   } catch (err) {
     console.error("Error fetching blog details:", err);
     this.paperDetails = {};
   }
},
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        if (newRoute.params.slug) {
          this.getBlogDetails(newRoute.params.slug);
          this.paperpage = true;
          this.viewPaper = false;
        } else {
          this.getBlogData();
          this.paperpage = false;
          this.viewPaper = true;
        }
      }
    }
  },

  mounted() {
   if (this.$route.params.slug) {
     this.getBlogDetails(this.$route.params.slug);
     this.paperpage = true;
     this.viewPaper = false;
   } else {
     this.getBlogData();
     this.paperpage = false;
     this.viewPaper = true;
   }
}
};
</script>

<style scoped>
.recentPosts,
.blogTitle {
  border-bottom: 1px dashed black;
}

.download-pdf,
.viewArticlebtn {
  border: 1px solid #d1a614;
  color: #d1a614;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}

.download-pdf:hover,
.viewArticlebtn:hover {
  color: #fff;
  background-color: #d1a614;
}

.header-title,
.paper-container {
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.article-header,
.sub-header-title {
  display: flex;
  justify-content: space-between;
}

.main-paper-header {
  border-radius: 5px;
  margin-bottom: 1rem;
}

/* .article :is(h1, h2, h3, h4, h5, h6, li) {
  margin: 10px 0 !important;
  padding: 0 !important;
} */
.article
h2,
h3 {
  color: #333;
  margin-bottom: 10px;
}

.article p {
  margin-bottom: 15px;
}

.article ul {
  padding-left: 20px;
}

.article li {
  margin-bottom: 5px;
}
</style>
