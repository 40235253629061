<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-6 variant="info"
            >Copyright, Grants and Ownership Declaration Policy
            <b-icon-arrow-down></b-icon-arrow-down>
          </b-button>
        </b-card-header>
        <b-collapse
          id="accordion-6"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-card-text>
              <div>
                <p>
                  All rights reserved. No part of the material protected by this
                  copyright notice may be reproduced or utilized in any form or
                  by any means, electronic or mechanical including photocopying,
                  recording or by any information storage and retrieval system,
                  without the prior written permission from the copyright owner.
                  However, permission is not required to copy abstracts of
                  papers on the condition that a full reference to the source is
                  given.
                </p>
                <h6>
                  Name of the Journal: International Journal of Scientific
                  Research in Engineering (IJSREAT)
                </h6>
              </div>
              <div>
                <h5>Copyright Policy : </h5>
                <p>
                  Authors note that paper cannot be withdrawn at any condition
                  once it is accepted. The Authors may not publish his/her The
                  Author may not publish his/her contribution anywhere else
                  without the prior written permission of the publisher unless
                  it has been changed substantially. The Author warrants that
                  his/her contribution is original, except for such excerpts
                  from copyrighted works as may be included with the permission
                  of the copyright holder and author thereof, that it contains
                  no libellous statements, and does not infringe on any
                  copyright, trademark, patent, statutory right, or propriety
                  right of others. The Author signs for and accepts
                  responsibility for releasing this material on behalf of any
                  and all co-authors. In return for these rights:
                </p>
                <p>
                  All proprietary rights other than copyrights, such as patent
                  rights.
                </p>
                <p>
                  The right to use all or part of this article, including tables
                  and figures in future works of their own, provided that the
                  proper acknowledgment is made to the Publisher as copyright
                  holder.
                </p>
                <p>
                  The right to make copies of this article for his/her own use,
                  but not for sale.
                </p>
                <p>
                  It is the responsibility of each author to ensure that papers
                  submitted to the journal are written with ethical standards in
                  mind, concerning plagiarism. Please note that all submissions
                  are thoroughly checked for plagiarism. If an attempt at
                  plagiarism is found in a published paper, the authors will be
                  asked to issue a written apology to the authors of the
                  original material. Any paper which shows obvious signs of
                  plagiarism will be automatically rejected and its authors may
                  be banned for duration of 01 years from publishing in Journal.
                  The authors will receive proper notification if such a
                  situation arises.
                </p>
                <p>
                  This paper has not been published in the same form elsewhere.
                </p>
                <p>
                  It will not be submitted anywhere else for publication prior
                  to acceptance/rejection by this Journal.
                </p>
                <p>
                  For any dispute or related violence, it will be discussed and
                  considered only in front of ‘Judiciary of Ariyalur’ at, Tamil
                  Nadu, and India.
                </p>
              </div>
              <div>
                <h5>Journal Author Rights : </h5>
                <p>
                  In order for journal to publish and disseminate research
                  articles, we need publishing rights. This is determined by a
                  publishing agreement between the author and Journal. This
                  agreement deals with the transfer or license of the copyright
                  to journal and authors retain significant rights to use and
                  share their own published articles. IJSREAT journal support
                  the need for authors to share, disseminate and maximize the
                  impact of their research and these rights, in Am Publications
                  proprietary journals* are defined below:
                </p>
              </div>
              <div>
                <p>
                  <b>For subscription articles: </b> Authors transfer copyright
                  to the publisher as part of a journal publishing agreement,
                  but have the right to:
                </p>
                <ul>
                  <li>
                    <p>
                      Share their article for Personal Use, Internal
                      Institutional Use and Scholarly Sharing purposes.
                    </p>
                  </li>
                  <li>
                    <p>
                      Retain patent, trademark and other intellectual property
                      rights (including research data).
                    </p>
                  </li>
                  <li>
                    <p>Proper attribution and credit for the published work.</p>
                  </li>
                </ul>
                <p>
                  <b>For open access articles: </b> Authors sign an exclusive
                  license agreement, where authors have copyright but license
                  exclusive rights in their article to the publisher**. In this
                  case authors have the right to:
                </p>
                <ul>
                  <li>
                    <p>
                      Share their article in the same ways permitted to third
                      parties under the relevant user license (together with
                      Personal Use rights)
                    </p>
                  </li>
                  <li>
                    <p>
                      Retain patent, trademark and other intellectual property
                      rights (including research data).
                    </p>
                  </li>
                  <li>
                    <p>Proper attribution and credit for the published work.</p>
                  </li>
                  <li>
                    <p>
                      *Please note that society or third party owned journals
                      may have different publishing agreements. Please see the
                      journal’s guide for authors for journal specific copyright
                      information.
                    </p>
                  </li>
                  <li>
                    <p>
                      **This includes the right for the publisher to make and
                      authorize commercial use, please see “Rights granted to
                      IJSREAT Journal” for more details.
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <h5>Rights granted to IJSREAT Journals: </h5>
                <p>
                  <b
                    >For both subscription and open access articles, published
                    in proprietary titles, journal is granted the following
                    rights:
                  </b>
                </p>
                <p>
                  The exclusive right to publish and distribute an article, and
                  to grant rights to others, including for commercial purposes.
                </p>
                <p>
                  For open access articles, journal will apply the relevant
                  third party user license where IJSREAT journal publish the
                  article on its online platforms.
                </p>
                <p>
                  The right to provide the article in all forms and media so the
                  article can be used on the latest technology even after
                  publication.
                </p>
                <p>
                  The authority to enforce the rights in the article, on behalf
                  of an author, against third parties, for example in the case
                  of plagiarism or copyright infringement.
                </p>
              </div>
              <div>
                <h5>Protecting Author Rights: </h5>
                <p>
                  Copyright aims to protect the specific way the article has
                  been written to describe an experiment and the results.
                  IJSREAT journal are committed to its authors to protect and
                  defend their work and their reputation and takes allegations
                  of infringement, plagiarism, ethic disputes and fraud very
                  seriously.
                </p>
                <p>
                  If an author becomes aware of a possible plagiarism, fraud or
                  infringement we recommend contacting journals publishing
                  contact who can then liaise with our in-house legal
                  department. Note that certain open access user licenses may
                  permit quite broad re-use that might otherwise be counted as
                  copyright infringement. For details about how to seek
                  permission to use an article see our permission page.
                </p>
              </div>
              <div>
                <h5>Disclaimer</h5>
                <p>
                  The opinions expressed and figures provided in this Journal;
                  IJSREAT, are the sole responsibility of the authors. The
                  publisher and the editors bear no responsibility in this
                  regard. Any and all such liabilities are disclaimed.
                </p>
                <p>
                  <i
                    >All disputes are subject to Ariyalur, Tamil Nadu, India
                    jurisdiction only.</i
                  >
                </p>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btn-info {
  background-color: #d1a614;
}

.card {
  border: none;
}
</style>