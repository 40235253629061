<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Call For Papers" parentPage="For Authors" />
    <CallForPaperComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import CallForPaperComponentVue from "../components/page_components/for_authors/CallForPaperComponent.vue";
import Footer from "@/components/layout/Footer";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    CallForPaperComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'IJSREAT Call for Papers - Submit Your Research Today',
      meta: [
        {
          name: 'description',
          content: 'IJSREAT invites researchers to submit original papers for publication. Join our global community of scholars in engineering and technology.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style>
</style>