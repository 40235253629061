<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="Contact Us" parentPage="Home" />
    <AddressBox />
    <Footer />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import AddressBox from "@/components/page_components/contact/AddressBox";
import Footer from "@/components/layout/Footer";

export default {
  name: "Contact",
  components: {
    TopHeader,
    MenuStyle1,
    PageHeader,
    AddressBox,
    Footer,
  },
  metaInfo() {
    return {
      title: 'Contact IJSREAT - Get in Touch with Our Team',
      meta: [
        {
          name: 'description',
          content: 'Have questions about publishing with IJSREAT? Contact our editorial team for support regarding submissions, peer review, and indexing.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style scoped>
</style>
