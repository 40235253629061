<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Copyright Form" parentPage="For Authors" />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import Footer from "@/components/layout/Footer";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    Footer,
  },
  metaInfo() {
      return {
        title: 'IJSREAT Copyright Form | Submission Guidelines',
        meta: [
          {
            name: 'description',
            content: 'Access IJSREATs copyright form and submission guidelines. Ensure your research complies with our publication policies.',
          },
          {
            name: 'keywords',
            content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: window.location.href,
          },
        ],
      };
  }
};
</script>

<style>
</style>