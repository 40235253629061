<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Aim And Scope" parentPage="About" />
    <MissionComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import Footer from "@/components/layout/Footer";
import MissionComponentVue from "../components/page_components/about_components/MissionComponent.vue";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    MissionComponentVue,
    Footer,
  },
  metaInfo() {
      return {
        title: 'IJSREAT Mission & Scope | Research Goals ',
        meta: [
          {
            name: 'description',
            content: 'Explore IJSREATs mission, scope, and research goals. Learn how we advance knowledge in science, engineering, and technology.',
          },
          {
            name: 'keywords',
            content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: window.location.href,
          },
        ],
      };
    }
  
};
</script>

<style scoped>
</style>