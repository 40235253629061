<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="FAQs" parentPage="About" />
    <FaqComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import FaqComponentVue from "../components/page_components/about_components/FaqComponent.vue";
import Footer from "@/components/layout/Footer";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    FaqComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'IJSREAT FAQs - Get Answers to Your Publishing Queries',
      meta: [
        {
          name: 'description',
          content: 'Find answers to common questions about IJSREAT, including submission guidelines, peer review, publication charges, and indexing information.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style>
</style>