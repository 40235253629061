<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Manuscript Submission" parentPage="For Authors" />
    <ManuscriptSubmissionComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import ManuscriptSubmissionComponentVue from "../components/page_components/for_authors/ManuscriptSubmissionComponent.vue";
import Footer from "@/components/layout/Footer";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    ManuscriptSubmissionComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'Submit Your Manuscript - IJSREAT Journal Publication',
      meta: [
        {
          name: 'description',
          content: 'Submit your manuscript to IJSREAT for peer-reviewed publication in engineering and technology. Follow our submission guidelines for a successful review.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style>
</style>