<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Ethics And Policies" parentPage="About" />
    <EthicsComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import EthicsComponentVue from "../components/page_components/about_components/EthicsComponent.vue";
import Footer from "@/components/layout/Footer";
export default {
  name: "EthicsAndPolicies",
  components: {
    MenuStyle1,
    PageHeader,
    EthicsComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'IJSREAT Ethics & Policies - Ensuring Research Integrity',
      meta: [
        {
          name: 'description',
          content: 'Explore IJSREAT’s ethics and publication policies, ensuring high standards of integrity, peer review, and transparency in scientific research.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style>
</style>

