<template>
    <div>
      <TopHeader />
      <MenuStyle1 />
      <PageHeader pageTitle="Conference" parentPage="Pages" />
      <Conference/>
      <Footer />
    </div>
  </template>
  
  <script>
  import TopHeader from "@/components/layout/TopHeader";
  import MenuStyle1 from "@/components/layout/MenuStyle1";
  import Conference from "@/components/page_components/conference/Conference.vue"
  import PageHeader from "@/components/layout/PageHeader";
  import Footer from "@/components/layout/Footer";
  
  export default {
    name: "Career",
    components: {
      TopHeader,
      MenuStyle1,
      Conference,
      PageHeader,
      Footer,
    },
     metaInfo() {
      return {
        title: 'IJSREAT Conferences | Events & Updates',
        meta: [
          {
            name: 'description',
            content: 'Stay updated on IJSREAT conferences, events, and calls for papers. Join global experts in science and technology discussions.',
          },
          {
            name: 'keywords',
            content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: window.location.href,
          },
        ],
      };
  }
    
  };
  </script>
  
  <style scoped>
  </style>
  