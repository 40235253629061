<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Instruction For Author" parentPage="For Authors" />
    <InstructionForAuthorComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import InstructionForAuthorComponentVue from "../components/page_components/for_authors/InstructionForAuthorComponent.vue";
import Footer from "@/components/layout/Footer";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    InstructionForAuthorComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'Author Instructions - Submit Your Paper to IJSREAT',
      meta: [
        {
          name: 'description',
          content: 'Follow our detailed instructions for authors to ensure a smooth submission process and compliance with IJSREAT’s publication standards.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style>
</style>