<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <BannerStyle1 />
    <FeaturesStyle1 />

    <!-- <CompaniesSlider /> -->
    <Services3Column />
    <!-- for authors -->
    <Speciality />
    <!-- IJIRE FEATURES -->
    <!-- <ProjectsHome /> -->
    <!-- OUR WORKS -->
    <Publish />
    <PostsHome />
    <Indexing/>
    <LetPublish/>
    <!-- <Reviews /> -->
    <OurAffordablePricingPlans/>
    <Footer />
  </div>
</template>

<script>
import TopHeader from "@/components/layout/TopHeader";
import MenuStyle1 from "@/components/layout/MenuStyle1";
import BannerStyle1 from "@/components/page_components/home/home_1/BannerStyle1";
import FeaturesStyle1 from "@/components/page_components/home/home_1/FeaturesStyle1";
// import CompaniesSlider from "@/components/common/companiesSlider";
import Services3Column from "@/components/page_components/home/home_1/Services3Column";
// import ProjectsHome from "@/components/page_components/home/ProjectsHome";
import Speciality from "@/components/page_components/home/Speciality";
import PostsHome from "@/components/page_components/home/PostsHome";
// import Reviews from "@/components/common/Reviews";
import Footer from "@/components/layout/Footer";
import LetPublish from "@/components/page_components/home/LetPublish";
import Indexing from "@/components/page_components/home/home_1/Indexing"

import Publish from "@/components/page_components/home/home_1/Publish";
import OurAffordablePricingPlans from "../components/page_components/home/home_1/OurAffordablePricingPlans";

export default {
  name: "Home1",
  components: {
    TopHeader,
    MenuStyle1,
    BannerStyle1,
    FeaturesStyle1,
    // CompaniesSlider,
    Services3Column,
    LetPublish,
    // ProjectsHome,
    Speciality,
    PostsHome,
    // Reviews,
    Footer,
    Publish,
    Indexing,
    OurAffordablePricingPlans,
  },
  metaInfo() {
    return {
      title: 'International Journal of Scientific Research in Engineering',
      meta: [
        {
          name: 'description',
          content: 'IJSRET publishes high-quality research in engineering and technology. Submit your paper or explore the latest scientific advancements.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>
<!-- Google tag (gtag.js) -->
<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16545603480">
</script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-16545603480');
</script> -->
<!-- Start of Tawk.to Script-->

<!--End of Tawk.to Script -->
<style scoped>
</style>
