<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Editorial Board" parentPage="About" />
    <EditorialComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import EditorialComponentVue from "../components/page_components/about_components/EditorialComponent.vue";
import Footer from "@/components/layout/Footer";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    EditorialComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'IJSREAT Editorial Board - Experts in Engineering Research',
      meta: [
        {
          name: 'description',
          content: 'Meet the esteemed editorial board of IJSREAT, composed of leading experts and scholars ensuring rigorous peer review and high-quality publications.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style>
</style>