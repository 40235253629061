<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Publication Ethics Policy" parentPage="About" />
    <PublicationComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import PublicationComponentVue from "../components/page_components/about_components/PublicationComponent.vue";
import Footer from "@/components/layout/Footer";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    PublicationComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'IJSREAT Publication Ethics - Research Integrity Standards',
      meta: [
        {
          name: 'description',
          content: 'Read about IJSREAT’s publication ethics policy, which ensures research integrity, prevents misconduct, and promotes transparency in scholarly publishing.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style>
</style>