<template>
    <div>
      <MenuStyle1 />
      <PageHeader pageTitle="Impact Factor" parentPage="About" />
      <ImpactFactor/>
      <Footer />
    </div>
  </template>
  
  <script>
  import MenuStyle1 from "@/components/layout/MenuStyle1";
  import PageHeader from "@/components/layout/PageHeader";
  import Footer from "@/components/layout/Footer";
  import ImpactFactor from "../components/page_components/about_components/ImpactFactor.vue"
  export default {
    name: "Blogs",
    components: {
      MenuStyle1,
      PageHeader,
      ImpactFactor,
      Footer,
    },
    metaInfo() {
      return {
        title: 'IJSREAT Impact Factor - Measuring Research Influence',
        meta: [
          {
            name: 'description',
            content: 'Explore the impact factor of IJSREAT, highlighting the journal’s significance and influence in the field of engineering and technology research.',
          },
          {
            name: 'keywords',
            content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: window.location.href,
          },
        ],
      };
    }
  };
  </script>
  
  <style>
  </style>