<template>
  <div class=" bg-f4f7fe">
    <div class="">
      <div class="section-title text-center">
        <h2>Our Compendium of Journals</h2>
        <p class="text-center">Fifth Dimension Research Publication</p>
      </div>

      <div class="row mt-30 ">
        <div class=" col-12 col-lg-4 col-sm-6">
          <div class="single-pricing-table border rounded-lg shadow center row">
            <div class="pricing-header mx-auto">
              <h3 style="padding-bottom: 5px;">IJIRE</h3>
            </div>

            <div class="price">
              <p>International Journal of Innovative Research in Engineering</p>
            </div>
            <div class="row pb-4 px-3">
              <div class=" pb-4 pb-xl-0 col-sm-10 col-xl-5 col-lg-7 mx-auto overflow-hidden">

                <img :src="IJIRELOGO" class="journalImage " />
              </div>
              <ul class="pricing-features row col-12 col-xl-7 ">
                <!-- <div class="col-md-5 col-lg-12 col-xl-5 "> -->
                <!-- </div> -->
                <div class="">
                  <li>
                    e-ISSN: <p class="title-content">2582-8746</p>
                  </li>
                  <li>
                    Product type: <p class="title-content">Journals</p>
                  </li>
                  <li>
                    Published By:
                    <p class="title-content">Fifth Dimension Research Publication</p>
                  </li>
                  <li>
                    Publication Month:
                    <p class="title-content">Bimonthly(Feb,Apr,Jun,Aug,Oct,Dec)</p>
                  </li>
                  <li>
                    Mode: <p class="title-content">Online</p>
                  </li>
                </div>
              </ul>
            </div>

            <div class="btn-box mx-auto">
              <a class="default-btn" href="https://theijire.com/" target="_blank">
                Visit Journal
                <span></span>
              </a>
            </div>
          </div>
        </div>

        <!-- <div class="col-12 col-lg-4 col-sm-6">
            <div class="single-pricing-table border rounded-lg shadow ">
              <div class="pricing-header mx-auto">
                <h3>IJSREAT</h3>
              </div>
  
              <div class="price">
                <p>International Journal of Scientific Research in Engineering & Technology</p>
              </div>
<div class="row pb-4 px-3">
  
              <img :src="IJRETLOGO" class="journalImage  pb-4 pb-xl-0 col-sm-10 col-xl-5 col-lg-7 mx-auto overflow-hiddencol-8 mx-auto overflow-hidden" />
              <ul class="pricing-features">
                <div class="">
                  <li>
                    e-ISSN: <p class="title-content">2583-1240</p>
                  </li>
                  <li>
                    Product type: <p class="title-content">Journals</p>
                  </li>
                  <li>
                    Published By:
                    <p class="title-content">Fifth Dimension Research Publication</p>
                  </li>
                  <li>
                    Publication Month:
                    <p class="title-content">Bimonthly(Feb,Apr,Jun,Aug,Oct,Dec)</p>
                  </li>
                  <li>
                    Mode: <p class="title-content">Online</p>
                  </li>
                </div>
              </ul>
              <div class="btn-box">
                <a class="default-btn w-" href="https://ijsreat.com/" target="_blank">
                  Visit Journal
                  <span></span>
                </a>
              </div>
            </div>
          </div>
   -->
        <div class=" col-12 col-lg-4 col-sm-6">
          <div class="single-pricing-table border rounded-lg shadow center row">
            <div class="pricing-header mx-auto">
              <h3 style="padding: 0px 5px;">IJRTMR</h3>
            </div>

            <div class="price">
              <p>International Journal of Recent Trends in Multidisciplinary Research</p>
            </div>
            <div class="row pb-4 px-3">
              <div class=" pb-4 pb-xl-0 col-sm-10 col-xl-5 col-lg-7 mx-auto overflow-hidden">
                <img :src="IJRTMRLOGO" class="journalImage " />
              </div>
              <ul class="pricing-features row col-12 col-xl-7">
                <!-- <div class="col-md-5 col-lg-12 col-xl-5 "> -->
                <!-- </div> -->
                <div class="">
                  <li>
                    e-ISSN: <p class="title-content">2583-0368</p>
                  </li>
                  <li>
                    Product type: <p class="title-content">Journals</p>
                  </li>
                  <li>
                    Published By:
                    <p class="title-content">Fifth Dimension Research Publication</p>
                  </li>
                  <li>
                    Publication Month:
                    <p class="title-content">Bimonthly(Feb,Apr,Jun,Aug,Oct,Dec)</p>
                  </li>
                  <li>
                    Mode: <p class="title-content">Online</p>
                  </li>
                </div>
              </ul>
            </div>
            <div class="btn-box mx-auto">
              <a class="default-btn" href="https://ijrtmr.com/" target="_blank">
                Visit Journal
                <span></span>
              </a>
            </div>
          </div>
        </div>

        <div class=" col-12 col-lg-4 col-sm-6 ">
          <div class="single-pricing-table border rounded-lg shadow center row">
            <div class="pricing-header mx-auto">
              <h3 style="padding-bottom: 5px;">INDJEEE</h3>
            </div>

            <div class="price">
              <p>Indian Journal of Electrical and Electronics Engineering</p>
            </div>
            <div class="row pb-4 px-3">
              <div class=" pb-4 pb-xl-0 col-sm-10 col-xl-5 col-lg-7 mx-auto overflow-hidden">
                <img :src="IJEEELOGO" class="journalImage " />
              </div>
              <ul class="pricing-features row col-12 col-xl-7">
                <!-- <div class="col-md-5 col-lg-12 col-xl-5 "> -->
                <!-- </div> -->
                <div class="">
                  <li>
                    e-ISSN: <p class="title-content">Applied</p>
                  </li>
                  <li>
                    Product type: <p class="title-content">Journals</p>
                  </li>
                  <li>
                    Published By:
                    <p class="title-content">Fifth Dimension Research Publication</p>
                  </li>
                  <li>
                    Publication Month:
                    <p class="title-content">3 Issues Per Year (Apr,Aug,Dec)</p>
                  </li>
                  <li>
                    Mode: <p class="title-content">Online</p>
                  </li>
                </div>
              </ul>
            </div>
            <div class="btn-box mx-auto">
              <router-link class="default-btn" to="https://fdrpjournals.org/indjeee">
                Visit Journal
                <span></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class=" col-12 col-lg-4 col-sm-6">
          <div class="single-pricing-table border rounded-lg shadow center row">
            <div class="pricing-header mx-auto">
              <h3 style="padding-bottom: 5px;">INDJECE</h3>
            </div>

            <div class="price">
              <p>Indian Journal of Electronics and Communication Engineering</p>
            </div>
            <div class="row pb-4 px-3">
              <div class=" pb-4 pb-xl-0 col-sm-10 col-xl-5 col-lg-7 mx-auto overflow-hidden">
                <img :src="IJECELOGO" class="journalImage" />
              </div>
              <ul class="pricing-features row col-12 col-xl-7">
                <!-- <div class="col-md-5 col-lg-12 col-xl-5 "> -->
                <!-- </div> -->
                <div class="">
                  <li>
                    e-ISSN: <p class="title-content">3048-6408</p>
                  </li>
                  <li>
                    Product type: <p class="title-content">Journals</p>
                  </li>
                  <li>
                    Published By:
                    <p class="title-content">Fifth Dimension Research Publication</p>
                  </li>
                  <li>
                    Publication Month:
                    <p class="title-content">3 Issues Per Year (Apr,Aug,Dec)</p>
                  </li>
                  <li>
                    Mode: <p class="title-content">Online</p>
                  </li>
                </div>
              </ul>
            </div>
            <div class="btn-box mx-auto">
              <router-link class="default-btn" to="https://fdrpjournals.org/indjece">
                Visit Journal
                <span></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class=" col-12 col-lg-4 col-sm-6">
          <div class="single-pricing-table border rounded-lg shadow center row">
            <div class="pricing-header mx-auto">
              <h3 style="padding-bottom: 5px;">INDJCST</h3>
            </div>

            <div class="price">
              <p>Indian Journal of Computer Science and Technology</p>
            </div>
            <div class="row pb-4 px-3">

              <div class=" pb-4 pb-xl-0 col-sm-10 col-xl-5 col-lg-7 mx-auto overflow-hidden">
                <img :src="INDCSTLOGO" class="journalImage " />
              </div>
              <ul class="pricing-features row col-12 col-xl-7">
                <!-- <div class="col-md-5 col-lg-12 col-xl-5 "> -->
                <!-- </div> -->
                <div class="">
                  <li>
                    e-ISSN: <p class="title-content">2583-5300</p>
                  </li>
                  <li>
                    Product type: <p class="title-content">Journals</p>
                  </li>
                  <li>
                    Published By:
                    <p class="title-content">Fifth Dimension Research Publication</p>
                  </li>
                  <li>
                    Publication Month:
                    <p class="title-content">3 Issues Per Year (Apr,Aug,Dec)</p>
                  </li>
                  <li>
                    Mode: <p class="title-content">Online</p>
                  </li>
                </div>
              </ul>
            </div>
            <div class="btn-box mx-auto">
              <a class="default-btn" href="https://indjcst.com/" target="_blank">
                Visit Journal
                <span></span>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Shape Images -->
    <!-- <div class="shape-img2"><img src="../../../../assets/shapes/shape-2.svg" alt="image" height="300"></div>
      <div class="shape-img3"><img src="../../../../assets/shapes/shape-3.svg" alt="image"></div>
      <div class="shape-img4"><img src="../../../../assets/shapes/shape-4.png" alt="image"></div>
      <div class="shape-img5"><img src="../../../../assets/shapes/shape-5.png" alt="image"></div>
      <div class="shape-img6"><img src="../../../../assets/shapes/shape-6.png" alt="image"></div>
      <div class="shape-img9"><img src="../../../../assets/shapes/shape-9.png" alt="image"></div>
      <div class="shape-img10"><img src="../../../../assets/shapes/shape-10.png" alt="image"></div> -->
  </div>
</template>

<script>
import INDCSTLOGO from '../../../../assets/Images/ijcse-nlogo.png'
import IJIRELOGO from '../../../../assets/Images/ijire-nlogo.png'
import IJRETLOGO from '../../../../assets/Images/ijret-nlogo.png'
import IJRTMRLOGO from '../../../../assets/Images/ijrtmr-nlogo.png'
import IJEEELOGO from '../../../../assets/Images/ijeee-nlogo.png'
import IJECELOGO from '../../../../assets/Images/ijece-nlogo.png'

export default {
  name: 'OurAffordablePricingPlans',
  data: function () {
    return {
      INDCSTLOGO,
      IJIRELOGO,
      IJRETLOGO,
      IJRTMRLOGO,
      IJEEELOGO,
      IJECELOGO
    }
  }
}
</script>

<style scoped>
.journalImage {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0px;

}

.pricing-features {
  /* row-gap: 1rem; */
  padding: 0px;
}

.single-pricing-table border rounded-lg shadow .pricing-features img {
  border-radius: 0px;
}

@media (min-width: 576px) {
  .price {
    min-height: 120px;
  }
}
@media (min-width: 760px) {
  .price {
    min-height: 80px;
  }
}

.price {
  padding: 0px 40px;
}

.price p {
  padding-top: 4px;
  padding-bottom: 4px;
}

h2 {
  color: #d1a614;
  font-weight: bold;
}

h3,
p {
  text-align: center;
}

li>p {
  text-align: left;
}

.single-pricing-table li>p {
  display: inline-flex;
  font-size: 14px;
}

.btn-box>a:hover {
  color: white !important;
}

.pricing-area-two {
  margin: 70px
}

.pricing-features li>p {
  display: inline-block;
}

.pricing-header {
  padding: 0px;
  padding-top: 15px;
  padding-bottom: 0px;
}

.title-content {
  margin: 0px 2px;
}
</style>