<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Peer Review Policy" parentPage="About" />
    <PeerReviewComponentVue />
    <Footer />
  </div>
</template>

<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import Footer from "@/components/layout/Footer";
import PeerReviewComponentVue from "../components/page_components/about_components/PeerReviewComponent.vue";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    PeerReviewComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'Peer Review Policy - IJSREAT Journal Standards',
      meta: [
        {
          name: 'description',
          content: 'Understand the peer review policy of IJSREAT, designed to maintain the highest quality of research in engineering and technology publications.',
        },
        {
          name: 'keywords',
          content: 'agency, business, corporate, company, clean, modern, personal, portfolio, professional, startup, studio',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }
};
</script>

<style>
</style>